<template>
  <BCard>
    <h3 class="mb-2 text-10">
      Data Komerce
    </h3>
    <BRow class="gap-[8px]">
      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Tanggal bergabung <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Tanggal Bergabung"
            :rules="'required'"
          >
            <FlatPickr
              :value="joinDate"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: 'j F Y',
                dateFormat: 'Y-m-d',
              }"
              placeholder="Tanggal, Bulan, Tahun"
              @input="$emit('update:joinDate', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Sektor Bisnis <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Sektor Bisnis"
            rules="required"
          >
            <VSelect
              :value="businessSectorId"
              label="partner_category_name"
              :reduce="option => option.id"
              :options="listSectorbusiness"
              placeholder="Pilih Sektor Binis"
              @input="$emit('update:businessSectorId', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Tipe Bisnis <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Tipe Bisnis"
            rules="required"
          >
            <VSelect
              :value="businessTypeId"
              label="name"
              :reduce="option => option.id"
              :options="listTypeBussiness"
              placeholder="Pilih Tipe Bisnis"
              @input="$emit('update:businessTypeId', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Nama Brand <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Nama Brand"
            rules="required"
          >
            <BFormInput
              :value="brandName"
              type="text"
              :formatter="capitalize"
              placeholder="Ketikkan nama brand kamu"
              :state="errors.length > 0 ? false : null"
              @input="$emit('update:brandName', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Jumlah Tim <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Jumlah Tim"
            rules="required"
          >
            <VSelect
              :value="teamMembers"
              :reduce="option => option.value"
              :options="teamMemberOptions"
              :filterable="true"
              placeholder="Jumlah tim kamu"
              @input="$emit('update:teamMembers', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Referensi <span class="text-danger">*</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Referensi"
            rules="required"
          >
            <VSelect
              :value="reference"
              :reduce="option => option.value"
              :options="referenceOptions"
              :filterable="true"
              placeholder="Pilih referensi kamu"
              @input="$emit('update:reference', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Bonus
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Bonus"
          >
            <BFormInput
              :value="bonus"
              type="text"
              :formatter="capitalize"
              placeholder="Masukkan bonus kamu"
              :state="errors.length > 0 ? false : null"
              @input="$emit('update:bonus', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Produk <span class="text-danger">*</span>
          </template>
          <div
            v-if="products.length > 0"
            class="d-flex flex-wrap gap-[12px] mb-1"
          >
            <div
              v-for="(e, i) in products"
              :key="i"
              class="max-w-full flex items-center p-[5px] !pl-[14px] gap-1 rounded-full bg-[#FCD4BE] text-black"
              :class="{ 'pointer-events-none !bg-[#d8d6de]': e.name === editProductField.name}"
            >
              <span class="w-[calc(100%-31px)] mr-[12px] truncate">{{ e.name }}</span>
              <!-- <button
                class="outline-none border-0 w-[25px] h-[25px] grid place-items-center rounded-full hover:bg-white"
                aria-label="edit-product"
                @click="() => showEditProductField(e)"
              >
                <span class="k-edit-2 text-[12px] font-semibold" />
              </button> -->
              <span
                class="k-close text-[25px] cursor-pointer rounded-full hover:bg-white"
                @click="() => openRemoveProductPopup(e)"
              />
            </div>
          </div>
          <BButton
            v-if="!productFieldActive"
            variant="outline-primary"
            class="ml-auto px-3"
            @click="showProductField"
          >
            + Tambah
          </BButton>
          <template v-else>
            <div class="flex items-center gap-[4px]">
              <BFormInput
                ref="productAdd"
                v-model="product"
                type="text"
                :placeholder="`${editProductField.name === '' ? 'Tambah' : 'Edit'} Produk`"
                @keyup.enter="saveProduct"
                @input="checkProductExist"
              />
              <b-button
                v-if="editProductField.name !== ''"
                variant="outline-secondary"
                class="h-[34px]"
                @click="cleanProductField"
              >
                Batal
              </b-button>
            </div>
            <small
              v-if="productExist"
              class="text-danger"
            >Kamu tidak bisa memasukkan produk yang sama</small>
            <small
              v-if="product.length > 50"
              class="text-danger"
            >Produk tidak boleh lebih dari 50 karakter</small>
          </template>
        </BFormGroup>
      </BCol>
    </BRow>
    <ConfirmPopup
      id="remove-product"
      title="Hapus Produk?"
      text="Produk yang kamu hapus tidak akan tampil di halaman Report Performa dan Aplikasi Mitra Komerce Talent!"
      confirm-text="Iya"
      cancel-text="Batal"
      @confirm="removeProduct"
    />
  </BCard>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { capitalize } from '@/libs/helpers'
import FlatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import '@core/scss/vue/libs/vue-select.scss'
import ConfirmPopup from '@/views/pages/komtim/training/training-program/ConfirmPopup.vue'

export default {
  components: {
    ValidationProvider,
    FlatPickr,
    VSelect,
    ConfirmPopup,
  },
  props: {
    joinDate: {
      type: String,
      default: null,
    },
    businessSectorId: {
      type: [Number, String],
      default: null,
    },
    businessTypeId: {
      type: [Number, String],
      default: null,
    },
    brandName: {
      type: String,
      default: null,
    },
    teamMembers: {
      type: String,
      default: '',
    },
    reference: {
      type: String,
      default: '',
    },
    bonus: {
      type: String,
      default: '',
    },
    listSectorbusiness: {
      type: Array,
      default: () => [],
    },
    listTypeBussiness: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      capitalize,
      showPassword: false,
      showConfirmPassword: false,
      productExist: false,
      teamMemberOptions: [
        { label: '0 - 4 orang', value: '0 - 4' },
        { label: '5 - 19 orang', value: '5 - 19' },
        { label: '20 - 99 orang', value: '20 - 99' },
        { label: '> 100 orang', value: '> 100' },
      ],
      referenceOptions: [
        { label: 'Media Sosial', value: 'Media Sosial' },
        { label: 'Google search', value: 'Mesin Pencari Google' },
        { label: 'Rekomendasi Teman', value: 'Rekomendasi Teman' },
        { label: 'Acara / Event', value: 'Acara' },
      ],
      productFieldActive: this.products.length === 0,
      product: '',
      editProductField: { id: 0, name: '' },
      productToRemove: null,
    }
  },
  methods: {
    showProductField() {
      this.productFieldActive = true
      setTimeout(() => { this.$refs.productAdd.focus() }, 50)
    },
    cleanProductField() {
      this.productFieldActive = false
      this.editProductField = { id: 0, name: '' }
      this.product = ''
    },
    showEditProductField(e) {
      this.editProductField = e
      this.product = e.name
      if (!this.productFieldActive) {
        this.showProductField()
        return
      }
      this.$refs.productAdd.focus()
    },
    editProduct() {
      const newProduct = { id: this.editProductField.id, name: this.product }
      const newProducts = this.products.map(e => (e.name === this.editProductField.name ? newProduct : e))
      this.$emit('update:products', newProducts)
      this.cleanProductField()
    },
    saveProduct() {
      if (this.product.length === 0 || this.product.length > 50 || this.productExist) return
      if (this.editProductField.name === '') {
        const temp = { name: this.product, id: 0 }
        this.$emit('update:products', [...this.products, temp])
        this.cleanProductField()
        return
      }
      this.editProduct()
    },
    openRemoveProductPopup(val) {
      this.productToRemove = val
      this.$bvModal.show('remove-product')
    },
    removeProduct() {
      const temp = this.products.filter(e => e !== this.productToRemove)
      if (temp.length === 0) this.productFieldActive = true
      this.$emit('update:products', temp)
    },
    checkProductExist() {
      this.productExist = this.products.some(p => p.name.toLowerCase() === this.product.toLowerCase())
    },
  },
}
</script>
