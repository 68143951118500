<template>
  <BCard>
    <h3 class="mb-2 text-10">
      Password
    </h3>
    <BRow class="gap-[8px]">
      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Password <span class="text-danger">{{ $route.params.id ? '' : '*' }}</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Password"
            :rules="$route.params.id ? 'min:8' : 'required|min:8'"
          >
            <BInputGroup class="input-group-merge">
              <BFormInput
                :value="password"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Password minimal 8 karakter"
                :state="errors.length > 0 ? false : null"
                @input="$emit('update:password', $event)"
              />
              <BInputGroupAppend is-text>
                <span
                  class="k-eye cursor-pointer"
                  @click="showPassword = !showPassword"
                />
              </BInputGroupAppend>
            </BInputGroup>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>

      <BCol md="8">
        <BFormGroup
          label-cols-md="4"
          label-class="font-medium text-7 text-black"
        >
          <template #label>
            Konfirmasi Password <span class="text-danger">{{ $route.params.id ? '' : '*' }}</span>
          </template>
          <ValidationProvider
            #default="{ errors }"
            name="Konfirmasi password"
            :rules="$route.params.id ? 'confirmed:Password' : 'required|confirmed:Password'"
          >
            <BInputGroup class="input-group-merge">
              <BFormInput
                :value="confirmationPassword"
                :state="errors.length > 0 ? false : null"
                :type="showConfirmPassword ? 'text' : 'password'"
                placeholder="Password minimal 8 karakter"
                @input="$emit('update:confirmationPassword', $event)"
              />
              <BInputGroup-append is-text>
                <span
                  class="k-eye cursor-pointer"
                  @click="showConfirmPassword = !showConfirmPassword"
                />
              </BInputGroup-append>
            </BInputGroup>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
  },
  props: {
    password: {
      type: String,
      default: '',
    },
    confirmationPassword: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
    }
  },
}
</script>
