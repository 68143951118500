<template>
  <BModal
    id="modalPreview"
    ref="modalPreview"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    :centered="true"
    style="width: 600px;"
    size="lg"
  >
    <div class="text-center my-1">
      <img
        :src="imageUrl"
        alt="Komerce"
        class="m-auto"
        style="border-radius: 8px;"
      >
      <div class="flex justify-center">
        <BButton
          size="md"
          variant="outline-primary"
          class="mt-1"
          style="width: 100%"
          @click="$bvModal.hide('modalPreview')"
        >
          Tutup
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
export default {
  name: 'ModalPreview',
  props: {
    imageUrl: {
      type: String || null,
      default: null,
    },
  },
}
</script>
