<template>
  <BCard>
    <h3 class="mb-2 text-10">
      Akun Bank
    </h3>
    <BRow>
      <BCol md="12">
        <template v-for="i in 6">
          <div
            :key="i+'a'"
            class="d-flex justify-content-between cursor-pointer gap-10 items-center mb-2 border-b pb-[8px]"
            @click="$data['visible'+i] = !$data['visible'+i]"
          >
            <span class="text-8 text-black font-semibold">Rekening {{ i }}</span>
            <span
              v-if="$data['visible'+i]"
              class="k-arrow-up-2 h-100 font-bold text-10"
            />
            <span
              v-else
              class="k-arrow-down-1 h-100 font-bold text-10"
            />
          </div>
          <BCollapse
            :key="i+'b'"
            v-model="$data['visible'+i]"
            class="mt-[5px]"
          >
            <BRow class="gap-[8px]">
              <BCol md="8">
                <BFormGroup
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Nama Bank
                  </template>
                  <ValidationProvider
                    #default="{ errors }"
                    :name="`Nama Bank ${i}`"
                    :rules="(bankAccounts[i-1].bank_owner_name || bankAccounts[i-1].bank_owner_no) ? 'required' : ''"
                  >
                    <v-select
                      :value="bankAccounts[i-1].bankCode ? bankAccounts[i-1].bankCode : bankAccounts[i-1].bank_name"
                      label="name"
                      :reduce="option => option"
                      :options="listBank"
                      :filterable="true"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ketik untuk mencari..."
                      :disabled="bankAccounts[i-1].bank_account_id ? true : false"
                      @input="$emit('update:bankCode', { value: $event, index: i - 1})"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </BFormGroup>
              </BCol>
              <BCol md="8">
                <BFormGroup
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    No Rekening
                  </template>
                  <ValidationProvider
                    #default="{ errors }"
                    :name="`No rekening ${i}`"
                    :rules="(bankAccounts[i-1].bank_owner_name || bankAccounts[i-1].bankCode) ? 'required|numeric' : 'numeric'"
                  >
                    <b-form-input
                      :value="bankAccounts[i-1].bank_owner_no"
                      placeholder="Ketikkan no rekening kamu"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="bankAccounts[i-1].bank_account_id ? true : false"
                      @input="$emit('update:bank_owner_no', { value: $event, index: i - 1})"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </BFormGroup>
              </BCol>
              <BCol md="8">
                <BFormGroup
                  label-cols-md="4"
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Nama Pemilik Rekening
                  </template>
                  <ValidationProvider
                    #default="{ errors }"
                    :name="`Nama Pemilik Rekening ${i}`"
                    :rules="(bankAccounts[i-1].bank_owner_no || bankAccounts[i-1].bankCode) ? 'required|alpha_spaces' : 'alpha_spaces'"
                  >
                    <b-form-input
                      :value="bankAccounts[i-1].bank_owner_name"
                      placeholder="ketikkan nama pemilik"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="bankAccounts[i-1].bank_account_id ? true : false"
                      @input="$emit('update:bank_owner_name', { value: $event, index: i - 1})"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </BFormGroup>
              </BCol>
            </BRow>
          </BCollapse>
        </template>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import VSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    ValidationProvider,
    VSelect,
  },
  props: {
    bankAccounts: {
      type: Array,
      default: () => [],
    },
    listBank: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible1: true,
      visible2: true,
      visible3: true,
      visible4: true,
      visible5: true,
      visible6: true,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID',
        },
      },
    }
  },
}
</script>
