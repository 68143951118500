<template>
  <BCard>
    <h3 class="mb-2 text-10">
      Data Kontak
    </h3>
    <BRow class="gap-[8px]">
      <BCol md="12">
        <div
          class="d-flex justify-content-between cursor-pointer gap-10 items-center mb-2 border-b pb-[8px]"
          @click="visibleBusiness = !visibleBusiness"
        >
          <span class="text-8 text-black font-semibold">Kontak Bisnis</span>
          <span
            v-if="visibleBusiness"
            class="k-arrow-up-2 h-100 font-bold text-10"
          />
          <span
            v-else
            class="k-arrow-down-1 h-100 font-bold text-10"
          />
        </div>

        <BCollapse
          v-model="visibleBusiness"
          class="mt-[5px]"
        >
          <BRow>
            <BCol md="8">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  No Hp
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="No HP"
                  rules="min:9|max:15"
                >
                  <cleave
                    :value="phoneBusiness"
                    class="form-control"
                    :options="options.phone"
                    placeholder="Ketikan nomer bisnis kamu"
                    @input="$emit('update:phoneBusiness', $event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
          </BRow>
        </BCollapse>

        <div
          class="d-flex justify-content-between cursor-pointer gap-10 items-center mb-2 border-b pb-[8px]"
          @click="visiblePic = !visiblePic"
        >
          <span class="text-8 text-black font-semibold">Kontak PIC Komtim</span>
          <span
            v-if="visiblePic"
            class="k-arrow-up-2 h-100 font-bold text-10"
          />
          <span
            v-else
            class="k-arrow-down-1 h-100 font-bold text-10"
          />
        </div>

        <BCollapse
          v-model="visiblePic"
          class="mt-[5px]"
        >
          <BRow>
            <BCol md="8">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Nama PIC
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Nama PIC"
                >
                  <BFormInput
                    :value="picName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ketikkan nama PIC kamu"
                    @input="$emit('update:picName', $event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
            <BCol md="8">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  No Hp
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="No HP"
                  rules="min:9|max:15"
                >
                  <Cleave
                    :value="picPhone"
                    class="form-control"
                    :options="options.phone"
                    placeholder="Ketikan nomer PIC kamu"
                    @input="$emit('update:picPhone', $event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
          </BRow>
        </BCollapse>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.id'

export default {
  components: {
    ValidationProvider,
    Cleave,
  },
  props: {
    phoneBusiness: {
      type: String,
      default: '',
    },
    picName: {
      type: String,
      default: '',
    },
    picPhone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visibleBusiness: true,
      visiblePic: true,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'ID',
        },
      },
    }
  },
}
</script>
